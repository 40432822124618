import request from "@/base/util/request.js";
//Gis Map Manage For Web Controller
//增加电子围栏，必须配对增加
export const addGisRangePoints = (data) => {
        return request({
            url: "/gisManage/addGisRangePoints",
            method: "post",
            data,
        })
    }
    //分页获取电子围栏信息，支持带条件查询
export const getGisRangePoints = (data) => {
    return request({
        url: "/gisManage/getGisRangePoints",
        method: "post",
        data,
    })
}


//根据名字和类型更新电子围栏信息
export const updateGisRangePoints = (data) => {
    return request({
        url: "/gisManage/updateGisRangePoints",
        method: "post",
        data,
    })
}



//根据id删除电子围栏信息
export const deleteGisRangePoints = (params) => {
    return request({
        url: "/gisManage/deleteGisRangePoints",
        method: "post",
        params,
    })
}

//获取初始化/地图中心经纬度信息
export const getGisInitPoints = () => {
    return request({
        url: "/gisManage/getGisInitPoints",
        method: "post",

    })
}