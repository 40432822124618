import { getGisInitPoints } from "../api/gis";
var gisMixin = {
    data: function() {
        return {
            mapCenter: [], //地图中心点经纬度，默认值
            path: [ //多边形经纬度，默认值
            ]
        }
    },
    created() {
        this.getGisInitPoints();

    },
    methods: {
        receatePath() {
            this.path = [];
            this.path.push([this.mapCenter[0] + 0.1, this.mapCenter[1] - 0.03])
            this.path.push([this.mapCenter[0], this.mapCenter[1] + 0.05])
            this.path.push([this.mapCenter[0] - 0.1, this.mapCenter[1] - 0.03])
        },
        getGisInitPoints() {
            getGisInitPoints().then(res => {
                if (res.code == 1) {
                    let strData = res.data;
                    let jsonData = JSON.parse(strData);
                    this.mapCenter = [jsonData.lng, jsonData.lat]
                    this.receatePath();
                    this.initMap();
                } else {
                    this.$message.error(res.msg);
                }
            }).catch(e => {});
        },
        resetSize() {
            let h =
                document.documentElement.clientHeight || document.body.clientHeight;
            this.tableHeight = h - 250;
        },
    }
}
export default gisMixin